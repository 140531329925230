<template>
  <!-- 微信跳转到该页面，为H5页面，兼容性需要重点考虑 -->
  <div class="index">
    <!-- <div class="title">仪器告警详细信息</div> -->
    <div class="content">
      <p><span>机号: </span><span>{{warmData.instrument_id}}</span></p>
      <p><span>仪器位置: </span><span>{{warmData.position?warmData.position:'-'}}</span> </p>
      <p><span>医院: </span><span>{{warmData.hospital}}</span> </p>
      <p><span>告警规则: </span><span>{{warmData.warning_rule}}</span></p>
      <p><span>告警解决方案: </span><span>{{warmData.warning_resolution}}</span></p>
    </div>
  </div>
</template>

<script>
import api from '@/api/message.js'
export default {
  data() {
    return {
      warmData: {
        hospital: '',
        instrument_id: '',
        position: '',
        warning_resolution: '',
        warning_rule: '',
      },
    }
  },
  created() {
    document.getElementsByTagName('html')[0].style.minWidth='auto';
    document.querySelector('body').style.minWidth='auto';
    document.querySelector('title').text='仪器告警详细信息'
  },
  mounted() {
    let query = this.$route.query
    this.get_warning_details(query.id)
    
  },
  methods: {
    get_warning_details(id) {
      api.warning_details({id}).then(res=> {
        if(res.code == 200){
          this.warmData = res.data;
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .index {
    font-size: 20px;
    background-color: #fff;
    
   
  }
  .title {
    height: 50px;
    text-align: center;
    line-height: 50px;
    background-color: #eee;
  }
  .content {
    padding: 10px;
    p{
      margin-bottom: 3px;
      span:nth-child(1){
        color: #999;
      }
      span:nth-child(2){
        color: #333;
      }
    }
  }
</style>